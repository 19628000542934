import _JSXStyle from "styled-jsx/style";
import React from "react";
var __jsx = React.createElement;
import { FAQ } from 'lib/constants/faq';
import { HOMEPAGE_GUIDES } from 'lib/constants/pages';
import useAtTop from 'lib/hooks/useAtTop';
import FaqSection from 'components/Faq/FaqSection';
import Features from 'components/landing/Features';
import { LocationPreviewList } from 'components/LocationPreview';
// import CreateListingAddressSearchBar from 'components/CreateListingAddressSearchBar';
import { ListingsAroundYou } from 'components/landing/ListingsAroundYou';
import Layout from 'components/Layout';
import { ListingsSearchBarWithFilters } from 'components/ListingsSearchBar';
import Logo, { TextLogo } from 'components/Logo';
import MainNav from 'components/Navbars/MainNav';
// import DownloadAppBanner from 'components/Banners/DownloadAppBanner';
import LazyImage from 'components/LazyImage';
import Link from 'components/Link';
import { useIsMobile } from 'components/Responsive';
import { SwitcherTexts } from 'components/transition/SwitcherTexts';
import landingBgImg from 'images/landing_bg.jpg';
var VERBS_WITH_VANLY = [
// <verb> with Vanly
'Park', 'Explore', 'Connect', 'Recharge', 'Shower', 'Travel'
// 'Eat',
// 'Pray',
// 'Love',
];
var HomePage = function HomePage() {
  var isMobile = useIsMobile();
  var isAtTop = useAtTop(isMobile ? 150 : 400);
  return __jsx(Layout, {
    navbar: __jsx(MainNav
    // topTransparent
    , {
      topShadow: false
      // navOverlap
      ,
      hideLogo: true,
      navbarBrand: __jsx(React.Fragment, null, __jsx(Link, {
        href: "/",
        className: "navbar-item"
      }, __jsx(Logo, {
        size: 48
      }), __jsx(TextLogo, {
        style: {
          height: 30,
          marginTop: 5,
          marginLeft: 10
        }
      }))),
      navbarStart: __jsx(React.Fragment, null, !isAtTop ? __jsx("span", {
        style: {
          flexShrink: 1
        },
        className: "jsx-2427031977" + " " + "navbar-item"
      }, __jsx(ListingsSearchBarWithFilters, null)) : null)
    })
    // banner={<PhotographerNetworkBanner />}
  }, __jsx("div", {
    className: "jsx-2427031977" + " " + "hero is-medium shadowed"
  }, __jsx(LazyImage, {
    src: landingBgImg,
    objectPosition: "right bottom"
  }), __jsx("div", {
    className: "jsx-2427031977" + " " + "hero-body"
  }, __jsx("div", {
    style: {
      paddingTop: '0rem',
      paddingBottom: '15rem',
      maxWidth: 700
    },
    className: "jsx-2427031977" + " " + "container"
  }, __jsx("h1", {
    className: "jsx-2427031977" + " " + "title is-size-1 is-size-3-mobile"
  }, __jsx(SwitcherTexts, {
    texts: VERBS_WITH_VANLY,
    maxShownAbove: 2
  }), ' ', __jsx("span", {
    className: "jsx-2427031977" + " " + "has-text-weight-normal"
  }, "with"), ' ', __jsx(TextLogo, {
    className: "has-text-link",
    style: {
      height: '1em',
      marginBottom: '-0.3em'
    }
  })), __jsx("h2", {
    className: "jsx-2427031977" + " " + "subtitle has-text-weight-semibold is-size-4 is-size-5-mobile mt-0 has-text-grey-dark"
  }, "Find and book safe overnight parking and amenities across the US."), __jsx("div", {
    className: "jsx-2427031977" + " " + "search-bar-wrapper"
  }, __jsx(ListingsSearchBarWithFilters, null))))), __jsx("section", {
    id: "around-you",
    className: "jsx-2427031977" + " " + "section"
  }, __jsx("div", {
    className: "jsx-2427031977" + " " + "container"
  }, __jsx(ListingsAroundYou, null))), __jsx("section", {
    id: "more-info",
    className: "jsx-2427031977" + " " + "section"
  }, __jsx("div", {
    className: "jsx-2427031977" + " " + "container"
  }, __jsx("h2", {
    className: "jsx-2427031977" + " " + "has-text-weight-bold is-size-3 mb-4"
  }, "Popular destinations"), __jsx(LocationPreviewList, {
    geos: HOMEPAGE_GUIDES
  }))), __jsx("section", {
    id: "how-to",
    className: "jsx-2427031977" + " " + "section"
  }, __jsx(Features, null), __jsx("div", {
    className: "jsx-2427031977" + " " + "container"
  }, __jsx("div", {
    className: "jsx-2427031977" + " " + "box flex"
  }, __jsx("div", {
    className: "jsx-2427031977"
  }, __jsx("h2", {
    className: "jsx-2427031977" + " " + "has-text-weight-bold is-size-3 mb-4"
  }, "Host your property on Vanly"), __jsx("p", {
    className: "jsx-2427031977" + " " + "is-size-6"
  }, "If you have a parking spot, driveway, or any other space you would like to share, you can list it on Vanly and start earning money."), __jsx("br", {
    className: "jsx-2427031977"
  }), __jsx("p", {
    className: "jsx-2427031977" + " " + "is-size-5"
  }, __jsx(Link, {
    href: "/host",
    className: "button is-rounded is-medium has-text-weight-semibold",
    style: {
      color: 'var(--box-color)'
    }
  }, "Learn more"))), __jsx(LazyImage, {
    src: "/static/landing/list.svg",
    alt: "",
    width: 500,
    height: 280,
    objectFit: "contain",
    className: "ml-2 is-hidden-mobile"
  })))), __jsx("hr", {
    className: "jsx-2427031977"
  }), __jsx("div", {
    id: "faq",
    className: "jsx-2427031977" + " " + "hash-link-anchor"
  }), __jsx("section", {
    className: "jsx-2427031977" + " " + "section"
  }, __jsx("div", {
    className: "jsx-2427031977" + " " + "container"
  }, __jsx(FaqSection, {
    title: "Frequently Asked Questions",
    items: FAQ
  }))), __jsx(_JSXStyle, {
    id: "2427031977"
  }, [".hero.jsx-2427031977{position:relative;}", ".hero.jsx-2427031977 .LazyImage{pointer-events:none;position:absolute;bottom:0;left:0;width:100%;height:65%;}", "@media (min-width:1750px){.hero.jsx-2427031977 .LazyImage{height:100%;}}", ".box.jsx-2427031977{padding:3rem;color:white;background:#ff4365;--box-color:#ff4365;}"]));
};
export default HomePage;